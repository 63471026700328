import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Experiences.scss";
import { urlFor, client } from "../../client";
import { FaBuilding } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";

const Experiences = () => {
  const [experiences, setExperiences] = useState([]);

  useEffect(() => {
    const query = '*[_type == "experiences"]';

    client.fetch(query).then((data) => {
      setExperiences(data);
    });
  }, []);

  return (
    <>
      <div className="main">
        <h2 className="head-text" style={{ color: "aliceblue" }}>
          {" "}
          Experiences{" "}
        </h2>

        <div className="app__experiences">
          {experiences.map((experience, index) => (
            <motion.div
              whileInView={{ opacity: 1 }}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.5, type: "tween" }}
              className="app__experience-item"
              key={experience.title + index}
            >
              <div className="experience-wrap">
                <div className="title">
                  <h2>{experience.title}</h2>
                  <p><FaBuilding />{experience.company}</p>
                </div>
                <div className="desc">
                  <p>{experience.date}</p>
                  <p><FaLocationDot />{experience.location}</p>
                  <p>{experience.tech}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Experiences, "app__experience"),
  "experiences",
  "app__whitebg"
);
