import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Projects.scss";
import { urlFor, client } from "../../client";
import { FaGithub } from "react-icons/fa";
import { FaGlobe } from "react-icons/fa";

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const query = '*[_type == "projects"]';

    client.fetch(query).then((data) => {
      setProjects(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text" style={{ color: "aliceblue" }}>
        Projects
      </h2>

      <div className="app__profiles">
        {projects.map((project, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: "tween" }}
            className="app__profile-item"
            key={project.title + index}
          >
            <div className="image">
              <img src={urlFor(project.imgUrl)} alt={project.title} />
              <div className="overlay">
                <h2 className="bold-text">{project.title}</h2>
                <p className="p-text">{project.description}</p>
                <div className="links">
                  <a href={project.github} target="_blank">
                    <FaGithub size={30} />
                  </a>
                  <a href={project.website} target="_blank">
                    <FaGlobe size={30} />
                  </a>
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Projects, "app__projects"),
  "projects",
  "app__whitebg"
);
