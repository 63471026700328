import React from "react";
import { FaGithub } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaMedium } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social" >
    <div>
      <a 
        href="https://www.linkedin.com/in/burak-kutluk-3a1128199/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaLinkedinIn/>
      </a>
    </div>
    <div>
      <a
        href="https://github.com/burakkutluk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaGithub />
      </a>
    </div>
    {/* <div>
      <a
        href="https://medium.com/@burakkutlukk"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaMedium />
      </a>
    </div> */}
  </div>
);

export default SocialMedia;
