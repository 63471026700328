import { About, Header, Footer, Skills, Projects, Experiences } from './container'
import { Navbar } from './components'
import './App.scss'

function App() {
  return (
    <div className="app">
      <Navbar />
      <Header />
      <About />
      <Experiences />
      <Skills />
      <Projects/>
      <Footer />
    </div>
  );
}

export default App;
