import React from "react";
import { color, motion } from "framer-motion";

import { AppWrap } from "../../wrapper";
import { images } from "../../constants";
import "./Header.scss";
import header from "../../assets/images (3).jpeg";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    transition: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const Header = () => (
  <div className="app__header app__flex">
    <motion.div
      whileInView={{ x: [-100, 0], opacity: [0, 1] }}
      transition={{ duration: 0.5 }}
      className="app__header-info"
    >
      <div className="info">
        <span style={{color:"#a9b3c4", fontSize:"40px"}}>Hello I am <h2 style={{color:"#fff", fontSize:"60px"}}>BURAK</h2></span>
        <span style={{color:"#a9b3c4", fontSize:"40px"}}>Software Engineer</span><br /> 
        <a className="hire" href="#contact">Contact Me</a>
      </div>
      <div className="header_img">
        <img src={header} alt="header" />
      </div>
    </motion.div>
  </div>
);

export default AppWrap(Header, "home");
